<template>
  <div class="">
    <v-card flat color="transparent">
      <v-card-title class="ml-0">
        <v-btn
          icon
          tile
          outlined
          text
          large
          class="mt-n2 ml-n4 rounded-lg"
          @click="$router.go(-1)"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>

        <h1 class="ml-4 text-title">
          Order {{ $route.params.id }}
        </h1>

        <v-btn icon tile outlined text class="mt-n2 ml-4 rounded-lg" large>
          <v-icon>
            mdi-file-multiple
          </v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn
          text
          outlined
          color="#47505e"
          class="white--text"
          large
          @click="showOrderModal = true"
        >
          <v-icon>mdi-folder-outline</v-icon>
          <span class="ml-2">View Details</span>
        </v-btn>
      </v-card-title>
    </v-card>

    <v-card :color="ant" height="120px" outlined>
      <v-card-title class="mt-2 ">
        {{ !getOrderDetail.topic ? '( no topic )': getOrderDetail.topic }}
      </v-card-title>
      <v-card-text class="mt-n4 pt-0">
        <span> {{ getOrderDetail.language }} </span>
        <v-btn
          :color="btnStatusColor"
          x-small
          class="ml-4"
        >
          {{ getOrderDetail.order_status }}
        </v-btn>
        <v-btn
          color="primary"
          x-small
          class="ml-4"
          v-if="$route.name === 'bidding'"
        >
          Bidding
        </v-btn>
        <v-btn icon tile v-if="order.order_status === 2">
          <v-icon small>
            mdi-lock-outline
          </v-icon>
        </v-btn>
        <span class="ml-4">
          {{ formatDateMonth(getOrderDetail.final_date) }}
        </span>
        <span class="ml-4" :class="{'red--text': timeErrorStatus(getOrderDetail.final_date)}">
          {{ formatOrderDate(getOrderDetail.final_date) }}
        </span>
      </v-card-text>
    </v-card>

     <v-row v-if="getOrderDetail.order_status !== 'bidding' && getOrderDetail.order_status !== 'open' " class="mt-2">
      <v-col>
        <WriterComponent :detail="getOrderDetail.writer" />
      </v-col>
      <v-col>
        <ChatStarter :room_id="roomId" v-if="roomId" />
      </v-col>
    </v-row>

    <ReviewComponent :orderDetail="getOrderDetail" class="mt-4" v-if="getOrderDetail.accept" />

    <contract :order="getOrderDetail" class="mt-4"  v-if="getOrderDetail.order_status === 'in-progress' || getOrderDetail.order_status === 'completed' " />

    <filesUpload class="mt-5 mb-7" v-if="getOrderDetail.order_status === 'in-progress' || getOrderDetail.order_status === 'completed' " />
    <Rating :show='ratingShow' :orderDetail="getOrderDetail" @close="ratingShow=false" />

    <OrderViewModal :show="showOrderModal" @close="showOrderModal = false" />

  </div>
</template>

<script>
import colorMxn from "@/mixins/colorMxn";
import validationMxn from '@/mixins/validation_mixin';
import timezoneMxn from '@/mixins/timezone_mixin';
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: "orderDetail",
  components: {
    OrderViewModal: () => import("@/modules/MyOrders/MyBids/orderViewModal"),
    contract: () => import("@/modules/writersDashboard/orderDetail/_components/contract"),
    filesUpload: () => import("@/components/filesUpload"),
    Rating: () => import('@/modules/Rating'),
    ReviewComponent: () => import('@/modules/MyOrders/components/reviewComponent'),
    WriterComponent: () => import("@/modules/MyOrders/MyBids/components/writerComponent"),
    ChatStarter: () => import("@/modules/writersDashboard/orderDetail/_components/chatStarter"),

  },
  mixins: [colorMxn, validationMxn, timezoneMxn],
  data() {
    return {
      showOrderModal: false,
      msg: "hey message",
      items: [
        "Writer's Rating",
        "Most Recent Bids",
        "Lowest bid",
        "Highest bid"
      ],
      order: {
        assinment_type: "Math Assignment",
        final_date: "Aug 11, 2020, 01:58 PM ",
        topic: "Machine learning",
        language: "English (US",
        order_status: 2
      },
      ratingShow: false,
    };
  },
  computed: {
    ...mapGetters(['getOrderDetail', 'getReload']),
    roomId() {
     const bid = this.getOrderDetail ? this.getOrderDetail.bids.filter((el) => el.writer_id === this.getOrderDetail.writer_id): null;
     const result = bid.length > 0 ? bid[0].room_id : null;
     return result;
    },
    btnStatusColor() {
      let color = "primary";
      // this.getOrderDetail.order_status === 'open'|| getOrderDetail.order_status === 'bidding' || getOrderDetail.order_status === 'in-progress' ? 'primary' : 'red'
      switch(this.getOrderDetail.order_status) {
        case 'open':
          color = 'primary';
          break;
        case 'bidding':
          color = 'primary';
          break;
        case 'in-progress':
          color = 'primary';
          break;
        case 'completed':
          color = this.essayGreen;
          break;
        case 'closed':
          color = 'error';
          break;
        default:
          break;
      }

      return color
    },
    
  },
  watch: {
    getReload(val) {
      if (val) {
        this.getOrders();
      }
    },
    getOrderDetail(val) {
      if (val.accept && !val.rating) {
        this.ratingShow = true;
      }
    }
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    ...mapMutations(['setMountUploadedFile', 'setOrderDetail', 'setReload']),
    async getOrders() {
      const payload = {
        page: 1,
      };
      const fullPayload = {
        params: payload,
        endpoint: `/order-detail/${this.$route.params.id}`
      };

      const response = await this.performGetActions(fullPayload);
      this.setMountUploadedFile(response.data[0].file);
      this.setOrderDetail(response.data[0]);
      this.setReload(false);
    },
  },
};
</script>

<style scoped>
.btn-border {
  border-radius: 4px;
  color: #fafafd;
  background-color: #232c3b;
  border: 1px solid #47505e;
}
a.v-tab {
  text-decoration: none;
}
</style>
